<template>
  <div class="product-block-content" id="products">
    <div class="product-block-content-container">
      <div class="product-block-content-header">Наши&nbsp;<span style="color: #7134F3">продукты:</span></div>
      <div class="product-block-content-description">
        ООО "Круг" ведет разработку продукта - <b>"Placetime"</b>, осуществляющем деятельность
        в области информационных технологий и разработки программного обеспечения, осуществляющем реализацию проекта зарегистрированного в ОЭЗ «Иннополис»
        г. Иннополис, Республика Татарстан, конечная цель реализации проекта – осуществление импортозамещения кроссплатформенных сервисов для самозанятых, малого, среднего и крупного бизнеса, включён в единый реестр российских программ для электронных вычислительных машин и баз данных.
      </div>
      <div class="product-block-products">
        <div class="product-block-products-item">
          <div class="product-block-products-item-icon-projectX"></div>
          <div class="product-block-products-item-text">
            <b>ProjectX</b> - новая платформа в сфере blockchain-технолгий, реализующая возможности применения смарт-контрактов.
          </div>
        </div>
        <div class="product-block-products-item" style="margin-top: 30px">
          <div class="product-block-products-item-icon-placetime"></div>
          <div class="product-block-products-item-text">
            Бизнес с PlaceTime мобилен и перемещается всегда
            и везде вместе с вами.
            Будьте готовы открыть возможность
            к выстраиванию новых эффективных коммуникаций, расширению влияния вашего бренда, а также к тому,
            что рядом с вами всегда могут найтись люди или комьюнити, в которых вы нуждаетесь прямо сейчас.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductBlock"
}
</script>

<style scoped>
.product-block-products-item {
  display: grid;
  grid-template-columns: 200px 650px;
}
.product-block-products-item-icon-projectX {
  background-image: url(~@/assets/images/project-x.svg);
  background-repeat: no-repeat;
  background-position: center;
}
.product-block-products-item-icon-placetime {
  background-image: url(~@/assets/images/placetime.svg);
  background-repeat: no-repeat;
  background-position: center;
}
.product-block-products-item-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  color: #000000;
  margin: auto;
}
.product-block-content-container {
  width: 903px;
  margin-top: 10px;
  height: 100%;
  margin-right: 20px;
}
.product-block-products {
  margin-top: 90px;
}
.product-block-content {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}
.product-block-content-header {
  height: 72px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 58px;
  line-height: 72px;
  display: flex;
  align-items: flex-end;
  color: #404040;
}
.product-block-content-description {
  height: 192px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  color: #000000;
  margin-top: 40px;
}
.product-block-products-item {
  width: 862px;
  background: #FFFFFF;
  box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.08);
  border-radius: 15px;
  padding: 38px 36px 40px 36px;
}
@media only screen and (max-width: 950px) {
  .product-block-products-item-text {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 20px;
    color: #000000;
    padding: 20px;
  }
  .product-block-products-item-icon-projectX {
    height: 50px;
    width: 80%;
    margin-top: 30px;
    margin-left: 20px;
    background-position: left;
  }
  .product-block-products-item-icon-placetime {
    height: 30px;
    width: 80%;
    margin-top: 30px;
    margin-left: 20px;
    background-image: url(~@/assets/images/mobile-placetime.svg);
    background-position: left;
  }
  .product-block-products-item {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0;
  }
  .product-block-content-container {
    width: 903px;
    margin-top: 60px;
    height: 100%;
    margin-left: 20px;
  }
  .product-block-content-header {
    width: 335px;
    height: 30px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 30px;
    color: #242424;
  }
  .product-block-content-description {
    height: 220px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: #000000;
  }
}
</style>
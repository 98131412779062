<template>
  <app-modal :is-modal-open="isModalOpen" @close="closeModal">
    <div v-if="!successSend" class="modal-wnd">
      <div class="cancel" @click="cancel"></div>
      <div class="content">
        <div class="title">
          <h1>Связаться с нами</h1>
          <p>Пожалуйста представьтесь, напишите свой вопрос или предложение
            и оставьте свои контакты, чтобы мы смогли ответить вам.</p>
          <p v-if="showErrorMessage" style="color: red; margin-top: 10px;">При сохранении возникли ошибки, попробуйте повторить свой запрос позже</p>
          <p v-if="showValidateErrorMessage" style="color: red; margin-top: 10px;">Не все поля формы заполнены корректно, проверьте правильность и повторите запрос</p>
        </div>
        <div class="form-items-first-row">
          <div class="form-item">
            <label for="name" class="form-item-label">Имя</label>
            <input
                type="text"
                id="name"
                class="form-item-field"
                placeholder="Как вас зовут?"
                v-model="name"
            />
          </div>
          <div class="form-item">
            <label for="mail" class="form-item-label">Email</label>
            <input
                type="text"
                id="mail"
                class="form-item-field"
                placeholder="Оставьте ваш email адрес"
                v-model="mail"
            />
          </div>
        </div>
        <div class="form-item-textarea">
          <label for="message" class="form-item-label">Ваш вопрос</label>
          <textarea
              id="message"
              class="form-item-field-textarea"
              placeholder="Напишите свой вопрос"
              v-model="message"
          />
        </div>
      </div>
      <div class="save-btn default-btn" @click="save">Отправить</div>
    </div>
    <div v-if="successSend" class="modal-wnd">
      <div class="cancel" @click="cancel"></div>
      <div class="content-success">
        <div class="title">
          <h1>Спасибо!</h1>
          <p>Благодарим за проявленный интерес. Мы уже получили ваше сообщение и ответим в ближайшее время.</p>
        </div>
      </div>
      <div class="save-btn default-btn" @click="cancel">Хорошо</div>
    </div>
  </app-modal>
</template>

<script>
import AppModal from '@/components/AppModal.vue';
import axios from 'axios'
import {modalHandler} from '@/mixins/modalHandler';

export default {
  name: 'ModalCmp',

  components: {
    AppModal,
  },

  mixins: [modalHandler],
  emits: ['closeModal'],
  data() {
    return {
      isModalOpen: false,
      name: null,
      mail: null,
      message: null,
      successSend: false,
      showErrorMessage: false,
      showValidateErrorMessage: false,
    };
  },

  methods: {
    cancel() {
      this.closeModal();
      this.successSend = false;
      this.name = null;
      this.mail = null;
      this.message = null;
      this.showErrorMessage = false;
    },
    save() {
      const reMail = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
      if (!reMail.test(this.mail)) {
        this.showErrorMessage = true;
        return;
      }
      const reName = /^[\WA-z]{2,}$/;
      if (!reName.test(this.name)) {
        this.showErrorMessage = true;
        return;
      }
      const reMessage = /^[\WA-z]{2,}$/;
      if (!reMessage.test(this.message)) {
        this.showErrorMessage = true;
        return;
      }
      this.showErrorMessage = false;
      axios.post(
          '/api/v1/question',
          {
            name: this.name,
            mail: this.mail,
            question: this.message,
          }
      ).then(() => {
        this.successSend = true;
        this.name = null;
        this.mail = null;
        this.message = null;
        this.showErrorMessage = false;
      }).catch(() => {
        this.showErrorMessage = true;
      })

    }
  },
};
</script>

<style scoped>
.modal-wnd {
  position: relative;
}
.cancel {
  position: absolute;
  width: 44px;
  height: 44px;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  top: 20px;
  right: 22px ;
  background-image: url(~@/assets/icons/cross.svg);
}
.content {
  width: 720px;
  max-height: 500px;
  display: flex;
  flex-direction: column;
  overflow: auto;
}
.content-success {
  width: 720px;
  max-height: 368px;
  display: flex;
  flex-direction: column;
  overflow: auto;
}
.title h1 {
  margin-left: 158px;
  width: 404px;
  height: 72px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 44px;
  line-height: 72px;
  text-align: center;
  color: #000000;
  margin-bottom: 10px;
}
.title p {
  width: 544px;
  height: 38px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #000000;
  margin-left: 88px;
}
.form-item {
  width: 310px;
  height: 64px;
  background: #F8F8F8;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  margin-left: 21px;
}
.form-item-textarea {
  width: 641px;
  min-height: 96px;
  background: #F8F8F8;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  margin-left: 40px;
  margin-top: 22px;
}
.form-item:first-child {
  margin-left: 40px;
}
.form-item-label {
   height: 32px;
   font-family: 'Open Sans';
   font-style: normal;
   font-weight: 600;
   font-size: 12px;
   line-height: 32px;
   color: #000000;
   margin-left: 32px;
 }
.form-item-field {
  position: absolute;
  border: none;
  background-color: inherit;
  height: 32px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  margin-left: 30px;
  margin-top: 22px;
  width: 250px;
}

.form-item-field:focus{
  outline: none;
}
.form-item-field::placeholder {
  color: #BDBDBD;
}
.form-item-field-textarea {
  border: none;
  background-color: inherit;
  height: 58px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  margin-left: 30px;
  width: 607px;
}
.form-item-field-textarea:focus{
  outline: none;
}
.form-item-field-textarea::placeholder {
  color: #BDBDBD;
}
.form-items-first-row {
  display: flex;
  margin-top: 40px;
}
.save-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 192px;
  height: 56px;
  border: 1px solid #7134F3;
  border-radius: 56px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 32px;
  text-align: center;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: #7134F3;
  margin: 24px auto 30px;
}
</style>

<template>
  <app-modal-mobile :is-modal-open="isModalOpen" @close="closeModal">
    <div v-if="!successSend" class="modal-wnd-mobile">
      <div class="cancel-mobile" @click="cancel"></div>
      <div class="content-mobile">
        <div class="title-mobile">
          <h1>Связаться с нами</h1>
          <p>Пожалуйста представьтесь, напишите свой вопрос или предложение
            и оставьте свои контакты, чтобы мы смогли ответить вам.</p>
          <p v-if="showErrorMessage" style="color: red; margin-top: 10px;">При сохранении возникли ошибки, попробуйте повторить свой запрос позже</p>
          <p v-if="showValidateErrorMessage" style="color: red; margin-top: 10px;">Не все поля формы заполнены корректно, проверьте правильность и повторите запрос</p>
        </div>
        <div class="form-item-mobile">
          <label for="name" class="form-item-label-mobile">Имя</label>
          <input
              type="text"
              id="name"
              class="form-item-field-mobile"
              placeholder="Как вас зовут?"
              v-model="name"
          />
        </div>
        <div class="form-item-mobile">
          <label for="mail" class="form-item-label-mobile">Email</label>
          <input
              type="text"
              id="mail"
              class="form-item-field-mobile"
              placeholder="Оставьте ваш email адрес"
              v-model="mail"
          />
        </div>
        <div class="form-item-textarea-mobile">
          <label for="message" class="form-item-label-mobile">Ваш вопрос</label>
          <textarea
              id="message"
              class="form-item-field-textarea-mobile"
              placeholder="Напишите свой вопрос"
              v-model="message"
          />
        </div>
      </div>
      <div class="save-btn-mobile default-btn" @click="save">Отправить</div>
    </div>
    <div v-if="successSend" class="modal-wnd-mobile">
      <div class="cancel-mobile" @click="cancel"></div>
      <div class="content-success-mobile">
        <div class="title-mobile">
          <h1>Спасибо!</h1>
          <p>Благодарим за проявленный интерес. Мы уже получили ваше сообщение и ответим в ближайшее время.</p>
        </div>
      </div>
      <div class="save-btn-mobile default-btn" @click="cancel">Хорошо</div>
    </div>
  </app-modal-mobile>
</template>

<script>
import axios from 'axios'
import {modalHandler} from '@/mixins/modalHandler';
import AppModalMobile from "@/components/AppModalMobile";

export default {
  name: 'ModalMobile',

  components: {
    AppModalMobile,
  },

  mixins: [modalHandler],
  emits: ['closeModal'],
  data() {
    return {
      isModalOpen: false,
      name: null,
      mail: null,
      message: null,
      successSend: false,
      showErrorMessage: false,
      showValidateErrorMessage: false,
    };
  },

  methods: {
    cancel() {
      this.closeModal();
      this.successSend = false;
      this.successSend = true;
      this.name = null;
      this.mail = null;
      this.message = null;
      this.showErrorMessage = false;
    },
    save() {
      const reMail = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
      if (!reMail.test(this.mail)) {
        this.showErrorMessage = true;
        return;
      }
      const reName = /^[\WA-z]{2,}$/;
      if (!reName.test(this.name)) {
        this.showErrorMessage = true;
        return;
      }
      const reMessage = /^[\WA-z]{2,}$/;
      if (!reMessage.test(this.message)) {
        this.showErrorMessage = true;
        return;
      }
      this.showErrorMessage = false;
      axios.post(
          '/api/v1/question',
          {
            name: this.name,
            mail: this.mail,
            question: this.message,
          }
      ).then(() => {
        this.successSend = true;
        this.name = null;
        this.mail = null;
        this.message = null;
        this.showErrorMessage = false;
      }).catch(() => {
        this.showErrorMessage = true;
      })

    }
  },
};
</script>

<style scoped>
.modal-wnd-mobile {
  position: relative;
}
.cancel-mobile {
  position: absolute;
  width: 44px;
  height: 44px;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  top: 20px;
  right: 22px ;
  background-image: url(~@/assets/icons/cross.svg);
}
.content-mobile {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.content-success-mobile {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.title-mobile h1 {
  height: 40px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 40px;
  text-align: center;
  color: #000000;
  margin: 60px auto 30px;
}
.title-mobile p {
  width: 325px;
  height: 60px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  color: #000000;
  margin: auto;
}
.form-item-mobile {
  height: 64px;
  background: #F8F8F8;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  margin-left: 25px;
  margin-right: 25px;
}
.form-item-textarea-mobile {
  min-height: 96px;
  background: #F8F8F8;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  margin-top: 22px;
  margin-left: 25px;
  margin-right: 25px;
}
.form-item-mobile:first-child {
  margin-top: 40px;
}
.form-item-label-mobile {
   height: 32px;
   font-family: 'Open Sans';
   font-style: normal;
   font-weight: 600;
   font-size: 12px;
   line-height: 32px;
   color: #000000;
   margin-left: 32px;
 }
.form-item-field-mobile {
  border: none;
  background-color: inherit;
  height: 32px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  margin-left: 30px;
  border-radius: 32px;
}

.form-item-field-mobile:focus{
  outline: none;
}
.form-item-field-mobile::placeholder {
  color: #BDBDBD;
}
.form-item-field-textarea-mobile {
  border: none;
  background-color: inherit;
  height: 58px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  margin-left: 30px;
}
.form-item-field-textarea-mobile:focus{
  outline: none;
}
.form-item-field-textarea-mobile::placeholder {
  color: #BDBDBD;
}
.form-items-first-row-mobile {
  display: flex;
  margin-top: 40px;
}
.save-btn-mobile {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 192px;
  height: 56px;
  border: 1px solid #7134F3;
  border-radius: 56px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 32px;
  text-align: center;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: #7134F3;
  margin: 24px auto 30px;
}
</style>

<template>
  <HeaderCmp/>
  <MainBanner/>
  <DevelopmentBlock/>
  <ProductBlock/>
  <KindsOfJob/>
  <MarketingBlock/>
  <PriceBlock/>
  <FooterCmp/>
</template>

<script>
import HeaderCmp from './components/HeaderCmp.vue'
import MainBanner from "@/components/MainBanner";
import DevelopmentBlock from "@/components/DevelopmentBlock";
import ProductBlock from "@/components/ProductBlock";
import KindsOfJob from "@/components/KindsOfJob";
import MarketingBlock from "@/components/MarketingBlock";
import FooterCmp from "@/components/FooterCmp";
import PriceBlock from "@/components/PriceBlock";

export default {
  name: 'App',
  components: {
    FooterCmp,
    DevelopmentBlock,
    MainBanner,
    HeaderCmp,
    ProductBlock,
    KindsOfJob,
    MarketingBlock,
    PriceBlock,
  },
  mounted() {
    document.title = 'ООО "Круг';
    document.meta = '<meta name="keywords" content="Круг Kruger kroog placetime pt placetimeclub pnft">';
  }
}
</script>

<style>
body {
  margin: 0;
}
@font-face {
  font-family: 'Inter';
  src: local('Inter'), url(./assets/font/Inter-Regular.ttf) format('truetype');
}
.default-btn {
  cursor: pointer;
}
.default-btn:hover {
  background-color: #7134F3;
  color: #FFFFFF;
}
.default-btn:active {
  background-color: #4F23AD;
  border-color: #4F23AD;
  color: #FFFFFF;
}
.default-btn-reverse {
  cursor: pointer;
  border: 1px solid #7134F3;
}
.default-btn-reverse:hover {
  background-color: #FFFFFF;
  color: #7134F3;
  border: 1px solid #7134F3;
}
.default-btn-reverse:active {
  background-color: #FFFFFF;
  border:  1px solid #4F23AD;
  color: #4F23AD;
}
a {
  all: unset;
}
</style>

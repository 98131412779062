<template>
  <div class="app-header">
    <div class="app-header-content">
      <div class="menu-item"><a href="#development">Разработка</a></div>
      <div class="menu-item"><a href="#products" @click="showMobileMenu = false;">Наши продукты</a></div>
      <div class="menu-item"><a href="#jobs" @click="showMobileMenu = false;">Виды деятельности</a></div>
      <div class="menu-item"><a href="#about" @click="showMobileMenu = false;">Place time</a></div>
      <div class="menu-btn default-btn" @click="showModal = true;">Связаться с нами</div>
    </div>
    <div class="mobile-header-content">
      <div class="mobile-menu-ico">
        <input type="checkbox" class="mobile-menu-ico-checkbox" id="mobile-menu" v-model="showMobileMenu">
        <label for="mobile-menu" class="mobile-menu-ico-ico"></label>
        <div class="mobile-header-menu">
          <div class="mobile-header-menu-item"><a href="#development" @click="showMobileMenu = false;">Разработка</a></div>
          <div class="mobile-header-menu-item"><a href="#products" @click="showMobileMenu = false;">Продукты</a></div>
          <div class="mobile-header-menu-item"><a href="#jobs" @click="showMobileMenu = false;">Виды услуг</a></div>
          <div class="mobile-header-menu-item"><a href="#about" @click="showMobileMenu = false;">О Place time</a></div>
          <div class="download-app">
            <div class="download-app-title">
              Скачать приложение
            </div>
            <div class="download-app-items">
              <a href="https://apps.apple.com/ru/app/kroog-io/id1536931290?l=en" target="_blank">
                <div class="download-app-item default-btn" style="margin-right: 5px;">
                  <div class="download-app-item-ico-apple"></div>
                  <div class="download-app-item-text">App Store</div>
                </div>
              </a>
              <a href="https://play.google.com/store/apps/details?id=io.kroog.app" target="_blank">
                <div class="download-app-item default-btn" style="margin-left: 5px;">
                  <div class="download-app-item-ico-google"></div>
                  <div class="download-app-item-text">Google Play</div>
                </div>
              </a>

            </div>
          </div>
          <div class="mobile-header-menu-footer">
            © 2021. PlaceTime, BRT Solutions Ltd. Peiraios, 30, 1st Flat/Office 1, Strovolos, 2023, Nicosia, Cyprus
          </div>
        </div>
      </div>
      <div class="mobile-menu-btn default-btn" @click="showModalMobile = true;">Связаться с нами</div>
    </div>
  </div>
  <ModalCmp
      :open-modal="showModal"
      @closeModal="closeModal"
  />
  <ModalMobile
      :open-modal="showModalMobile"
      @closeModal="closeModalMobile"
  />
</template>

<script>
import ModalCmp from '@/components/ModalCmp';
import ModalMobile from '@/components/ModalMobile';

export default {
  name: "HeaderCmp",
  components: {
    ModalMobile,
    ModalCmp,
  },
  data: function () {
    return {
      showModal: false,
      showModalMobile: false,
      showMobileMenu: false,
      values: [],
    };
  },
  methods: {
    closeModal() {
      this.showModal = false;
    },
    closeModalMobile() {
      this.showModalMobile = false;
    },
  },
};
</script>

<style scoped>
.app-header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.app-header-content {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 1051px;
}
.menu-item {
  margin-right: 20px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 32px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  margin-top: 20px;
  cursor: pointer;
}
.menu-item:hover {
  text-decoration: underline;
}
.menu-btn {
  margin-left: auto;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 32px;
  text-align: center;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: #7134F3;
  border: 1px solid #7134F3;
  border-radius: 56px;
  padding: 12px 32px;
  margin-top: 8px;
}
.mobile-header-content {
  display: none;
}
.mobile-menu-ico-ico {
  content: url(~@/assets/icons/burger.svg);
}
.mobile-menu-ico-checkbox {
  display: none;
}
.mobile-menu-ico-checkbox:checked + .mobile-menu-ico-ico {
  content: url(~@/assets/icons/cross.svg);
  margin: 17px;
}
.mobile-menu-btn {
  padding: 12px 32px;
  border: 1px solid #7134F3;
  border-radius: 56px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 32px;
  text-align: center;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: #7134F3;
  margin-left: auto;
  margin-top: 5px;
  margin-right: 5px;
}
.mobile-header-menu {
  display: none;
  height: 717px;
  background-color: black;
  width: 100%;
  position: absolute;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.mobile-menu-ico-checkbox:checked ~ .mobile-header-menu {
  display: flex;
  z-index: 15;
}
.mobile-header-menu-item {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #FFFFFF;
  margin-bottom: 23px;
  cursor: pointer;
}
.mobile-header-menu-item:hover {
  text-decoration: underline;
}
.mobile-header-menu-item:first-child {
  margin-top: 60px;
}
.download-app-items {
  display: flex;
  justify-content: flex-start;
  align-items: center;

}
.download-app-title {
  margin-top: 150px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 56px;
  color: #FFFFFF;
  padding-left: 40px;
}
.download-app-items {
  display: flex;
}
.download-app-item {
  /*padding: 9px 18px;*/
  width: 164px;
  height: 50px;
  border: 2px solid #FFFFFF;
  border-radius: 56px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.download-app-item-ico-apple {
  background-image: url(~@/assets/icons/apple.svg);
  width: 30px;
  height: 30px;
  margin-left: 15px;
  margin-bottom: 5px;
}
.download-app-item-ico-google {
  background-image: url(~@/assets/icons/google.svg);
  width: 30px;
  height: 30px;
  margin-left: 15px;
  margin-bottom: 5px;
}
.download-app-item-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 32px;
  text-align: center;
  color: #FFFFFF;
  margin-left: 14px;
}
.mobile-header-menu-footer {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
  text-align: center;
  color: rgba(255, 255, 255, 0.5);
  margin-top: 55px;
}
@media only screen and (max-width: 600px) {
  .app-header-content {
    display: none;
  }
  .mobile-header-content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
  }
}
</style>
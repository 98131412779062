<template>
  <div v-show="showModal" class="overlay-mobile" @click.self="closeModal">
    <section class="modal-mobile">
      <slot></slot>
    </section>
  </div>
</template>

<script>
export default {
  props: {
    isModalOpen: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['close'],

  data() {
    return {
      showModal: this.isModalOpen,
    };
  },

  watch: {
    isModalOpen() {
      if (this.isModalOpen) {
        this.showModal = true;
        document.documentElement.style.overflow = 'hidden';

        return;
      }

      this.showModal = false;
      document.documentElement.style.overflow = 'auto';
    },
  },

  methods: {
    closeModal() {
      this.$emit('close');
    },
  },
};
</script>

<style>
.overlay-mobile {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.67);
  z-index: 7;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}
.modal-mobile {
  background: #FFFFFF;
  box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.08);
  width: 100%;
  height: 100%;
}

</style>

<template>
  <div class="banner-background">
    <div class="banner-background-image">
      <div class="banner-background-image-container">
        <div class="logo">
          <div class="logo-ico"></div>
          <div class="logo-text-block">
            <div class="logo-header"></div>
            <div class="logo-description"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MainBanner',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.banner-background {
  background: linear-gradient(270.23deg, #F9F5FF 0.17%, rgba(249, 245, 255, 0) 99.8%), #F3F3F3;
  height: 470px;
  margin-top: 10px;
  z-index: 1;
}
.banner-background-image {
  background-image: url(~@/assets/images/banner-image.svg);
  background-repeat: no-repeat;
  background-position: right;
  width: 100%;
  height: 100%;
  z-index: 2;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.banner-background-image-container {
  width: 903px;
}
.logo {
  display: flex;
  flex-direction: row;

}
.logo-ico {
  background-image: url(~@/assets/images/logo.svg);
  width: 93px;
  height: 93px;
  z-index: 3;
}
.logo-header {
  background-image: url(~@/assets/images/logo-header.svg);
  width: 225px;
  height: 73px;
  z-index: 3;
  margin-left: 15px;
}
.logo-description {
  background-image: url(~@/assets/images/logo-description.svg);
  width: 225px;
  height: 10px;
  z-index: 3;
  margin-left: 15px;
  margin-top: 10px;
}
.logo-text-block {
  display: flex;
  flex-direction: column;
}
@media only screen and (max-width: 600px) {
  .banner-background-image-container {
    width: auto;
  }
  .logo-ico {
    width: 53px;
    height: 53px;
    background-size: 53px 53px;
  }
  .logo-header {
    width: 130px;
    height: 42px;
    background-size: 130px 42px;
    margin-left: 8px;
  }
  .logo-description {
    width: 130px;
    height: 6px;
    background-size: 130px 6px;
    margin-left: 8px;
    margin-top: 6px;
  }
  .banner-background {
    height: 306px;
  }
}
</style>

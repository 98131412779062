<template>
  <div class="marketing-block-content" id="about">
    <div class="marketing-block-content-container">
      <div class="marketing-block-content-header"><span style="color: #7134F3">PlaceTime&nbsp;</span>— наше решение для вашего бизнеса</div>
      <a href="https://www.placetime.pro/" target="_blank" class="description-link"><div class="about-place-time default-btn-reverse">Подробнее</div></a>
      <div class="beautiful-p"></div>
      <div class="beautiful-p2"></div>
      <div class="phone-img"></div>
      <div class="phone-img2"></div>
      <div class="benefits">
        <div class="benefits-title">Чем выгодно <span style="color: #7134F3">для бизнеса</span></div>
        <div class="benefits-list">
          <div class="benefits-list-item">
            <div class="benefits-list-ico suitcase"></div>
            <div class="benefits-list-text">Нанесите на карту мира все офисы, точки продаж, представительства и объедините их под одним брендом</div>
          </div>
          <div class="benefits-list-item">
            <div class="benefits-list-ico people"></div>
            <div class="benefits-list-text">Организовывайте встречу без посредников, привлекайте новых участников, партнеров, инвесторов в любой точке мира</div>
          </div>
          <div class="benefits-list-item">
            <div class="benefits-list-ico mark"></div>
            <div class="benefits-list-text">Развивайте бизнес как локально в радиусе 10 км от вас, так и полномасштабно - в регионе, стране или на весь мир</div>
          </div>
          <div class="benefits-list-item">
            <div class="benefits-list-ico molnia"></div>
            <div class="benefits-list-text">Оперативно сообщайте об изменениях, обновления и любых других новостях</div>
          </div>
          <div class="benefits-list-item">
            <div class="benefits-list-ico search"></div>
            <div class="benefits-list-text">Расширьте возможности поиска сотрудников и экспертов для всех ваших проектов</div>
          </div>
        </div>
        <div class="benefits-download-block">
          <a href="https://apps.apple.com/ru/app/kroog-io/id1536931290?l=en" target="_blank">
            <div class="benefits-download-block-btn default-btn">
              <div class="benefits-download-block-btn-ico apple"></div>
              <div>App Store</div>
            </div>
          </a>
          <a href="https://play.google.com/store/apps/details?id=io.kroog.app" target="_blank">
            <div class="benefits-download-block-btn default-btn">
              <div class="benefits-download-block-btn-ico google"></div>
              <div>Google Play</div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MarketingBlock"
}
</script>

<style scoped>
.description-link {
  left: 0px;
  position: relative;
}
.default-btn:hover .google {
  background-image: url(~@/assets/icons/google-white.svg);
  background-repeat: no-repeat;
}
.default-btn:hover .apple {
  background-image: url(~@/assets/icons/apple-white.svg);
  background-repeat: no-repeat;
}
.default-btn:active .apple {
  background-image: url(~@/assets/icons/apple-white.svg);
  background-repeat: no-repeat;
}
.default-btn:active .google {
  background-image: url(~@/assets/icons/google-white.svg);
  background-repeat: no-repeat;
}
.benefits-download-block-btn-ico {
  width: 25px;
  height: 25px;
}
.google {
  background-image: url(~@/assets/icons/google-violet.svg);
  background-repeat: no-repeat;
}
.apple {
  background-image: url(~@/assets/icons/apple-violet.svg);
  background-repeat: no-repeat;
}
.benefits-download-block-btn {
  margin-top: 25px;
  margin-left: 45px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 193px;
  height: 56px;
  border: 1px solid #7134F3;
  border-radius: 56px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 32px;
  text-align: center;
  color: #7134F3;
}
.benefits-download-block {
  display: flex;
  flex-direction: row;
  gap: 5px;
}
.benefits-list-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
}
.suitcase {
  background-image: url(~@/assets/icons/suitcase.svg);
  background-repeat: no-repeat;
}
.people {
  background-image: url(~@/assets/icons/people.svg);
  background-repeat: no-repeat;
}
.mark {
  background-image: url(~@/assets/icons/mark.svg);
  background-repeat: no-repeat;
}
.molnia {
  background-image: url(~@/assets/icons/molnia.svg);
  background-repeat: no-repeat;
}
.search {
  background-image: url(~@/assets/icons/search.svg);
  background-repeat: no-repeat;
}
.benefits-list-item {
  display: grid;
  grid-template-columns: 50px 450px;
  margin-top: 40px;
}
.benefits {
  position: absolute;
  left: 500px;
  top: 590px;
}
.benefits-title {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 44px;
  line-height: 72px;
}
.phone-img {
  background-image: url(~@/assets/images/phone1.png);
  background-repeat: no-repeat;
  z-index: 3;
  width: 400px;
  height: 650px;
  position: absolute;
  left: -175px;
  top: 560px;
}
.phone-img2 {
  background-image: url(~@/assets/images/phone2.png);
  background-repeat: no-repeat;
  z-index: 3;
  width: 400px;
  height: 650px;
  position: absolute;
  left: 50px;
  top: 645px;
}
.beautiful-p {
  background-image: url(~@/assets/images/beatifulP.png);
  background-repeat: no-repeat;
  z-index: 3;
  width: 200px;
  height: 200px;
  position: absolute;
  left: 550px;
  top: 170px;
}
.beautiful-p2 {
  background-image: url(~@/assets/images/beautifulP2.png);
  background-repeat: no-repeat;
  z-index: 3;
  width: 250px;
  height: 250px;
  position: absolute;
  left: 650px;
  top: 320px;
}
.marketing-block-content-container {
  margin-top: 10px;
  margin-right: 20px;
  width: 903px;
  position: relative;
}
.marketing-block-content {
  margin-top: 80px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  background-image: url(~@/assets/images/pre-footer.svg);
  background-repeat: no-repeat;
  background-position: bottom;
  z-index: 2;
  height: 1460px;
}
.marketing-block-content-header {
  height: 72px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 58px;
  line-height: 72px;
  color: #404040;
  margin-top: 10px;
  margin-bottom: 10px;
  display: inline;
}
.about-place-time {
  background: #7134F3;
  border-radius: 56px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 32px;
  text-align: center;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  width: 159px;
  height: 56px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #FFFFFF;
  margin-top: 40px;
}
@media only screen and (max-width: 1040px) {
  .description-link {
    left: -85px;
    position: relative;
  }
  .about-place-time {
    align-self: flex-start;
    margin-top: 55px;
  }
  .marketing-block-content {
    height: 1560px;
  }
  .benefits-download-block {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  .benefits-download-block-btn {
    margin-top: 25px;
    margin-left: 25px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 56px;
    border: 1px solid #7134F3;
    border-radius: 56px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 32px;
    text-align: center;
    color: #7134F3;

    box-sizing: border-box;
    padding: 12px 32px 12px 28px;
    gap: 10px;
    width: 275px;
  }
  .phone-img2 {
    background-image: url(~@/assets/images/phoneMobile2.png);
    width: 250px;
    position: relative;
    left: 45px;
    top: -105px;
  }
  .phone-img {
    background-image: url(~@/assets/images/phoneMobile.png);
    width: 250px;
    position: relative;
    left: -60px;
    top: -90px;
    margin-bottom: -375px;
  }
  .beautiful-p {
    background-image: url(~@/assets/images/beautifulPMobile.png);
    background-repeat: no-repeat;
    z-index: 3;
    width: 200px;
    height: 200px;
    position: relative;
    left: -60px;
    top: 0px;
  }
  .beautiful-p2 {
    background-image: url(~@/assets/images/beautifulPMobile2.png);
    background-repeat: no-repeat;
    z-index: 3;
    width: 150px;
    height: 350px;
    position: relative;
    left: 60px;
    top: -75px;
    margin-bottom: -75px;
  }
  .marketing-block-content-container {
    width: 903px;
    margin-top: 60px;
    height: 100%;
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  .marketing-block-content-header {
    /*width: 335px;*/
    height: 30px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 30px;
    color: #242424;
    align-self: flex-start;
  }
  .benefits {
    position: relative;
    left: 0px;
    width: 300px;
    top: -115px;
  }
  .benefits-list-item {
    grid-template-columns: 30px minmax(250px, 1050px);
    margin-top: 20px;
  }
  .benefits-title {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
  }
  .benefits-list-text {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
  }
  .benefits-list-ico {
    width: 20px;
    height: 20px;
    background-size: 100%;
  }
}
</style>
<template>
  <div class="kinds-of-job-content" id="jobs">
    <div class="kinds-of-job-content-container">
      <div class="kinds-of-job-content-header">Виды&nbsp;<span style="color: #7134F3">деятельности</span></div>
      <div class="kinds-of-job-grid">
        <div class="kinds-of-job-item">
          <div class="kinds-of-job-item-icon dev-ico"></div>
          <div class="kinds-of-job-item-title">Разработка</div>
          <div class="kinds-of-job-item-list">
            <ul>
              <li>Мобильных приложений</li>
              <li>Кросс платформенных решений</li>
              <li>Гео сервисов</li>
              <li>Блокчейн решений и в сфере блокчейн технологий</li>
            </ul>
          </div>
        </div>
        <div class="kinds-of-job-item">
          <div class="kinds-of-job-item-icon design-ico"></div>
          <div class="kinds-of-job-item-title">Дизайн</div>
          <div class="kinds-of-job-item-list">
            <ul>
              <li>Разработка дизайна и фирменного стиля</li>
              <li>Разработка и редизайн существующих мобильных приложений</li>
              <li>Детальная проработка UI/ UX</li>
            </ul>
          </div>
        </div>
        <div class="kinds-of-job-item">
          <div class="kinds-of-job-item-icon rocket-ico"></div>
          <div class="kinds-of-job-item-title">Product</div>
          <div class="kinds-of-job-item-list">
            <ul>
              <li>Проработка продукта с нуля</li>
              <li>Продуктовая разработка</li>
              <li>Составление ТЗ и технической документации</li>
            </ul>
          </div>
        </div>
        <div class="kinds-of-job-item">
          <div class="kinds-of-job-item-icon list-ico"></div>
          <div class="kinds-of-job-item-title">QA</div>
          <div class="kinds-of-job-item-list">
            <ul>
              <li>Тестирование</li>
              <li>Автоматизированное тестирование</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "KindsOfJob"
}
</script>

<style scoped>
.kinds-of-job-content-container {
  width: 903px;
  margin-top: 10px;
  height: 100%;
  margin-right: 20px;
}
.kinds-of-job-content {
  margin-top: 80px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}
.kinds-of-job-content-header {
  height: 72px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 58px;
  line-height: 72px;
  display: flex;
  align-items: flex-end;
  color: #404040;
  margin-top: 10px;
  margin-bottom: 10px;
}
.kinds-of-job-grid {
  display: grid;
  grid-template-columns: 440px 440px;
  gap: 57px;
  margin-top: 77px;
}
.kinds-of-job-item {
  height: 395px;
  background: #FFFFFF;
  box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.08);
  border-radius: 32px;
  position: relative;
}
.kinds-of-job-item-icon {
  width: 80px;
  height: 80px;
  background-repeat: no-repeat;
  position: absolute;
  right: 25px;
  top: 25px;
}
.dev-ico {
  background-image: url(~@/assets/icons/development.svg);
}
.design-ico {
  background-image: url(~@/assets/icons/design.svg);
}
.rocket-ico {
  background-image: url(~@/assets/icons/roket.svg);
}
.list-ico {
  background-image: url(~@/assets/icons/list.svg);
}
.kinds-of-job-item-title {
  margin-left: 40px;
  margin-top: 40px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 64px;
  color: #000000;
}
.kinds-of-job-item-list {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 40px;
  color: #000000;
}
.kinds-of-job-item-list li::before {
  content: url(~@/assets/icons/violet-dot.svg);
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}
.kinds-of-job-item-list ul {
  list-style: none;
}
@media only screen and (max-width: 950px) {
  .kinds-of-job-grid {
    display: grid;
    grid-template-columns: 345px;
    margin-top: 77px;
    justify-content: center;
  }
  .kinds-of-job-content-container {
    width: 903px;
    margin-top: 60px;
    height: 100%;
    margin-left: 20px;
  }
  .kinds-of-job-content-header {
    width: 335px;
    height: 30px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 30px;
    color: #242424;
  }
  .kinds-of-job-item-title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
  }
  .kinds-of-job-item-icon {
    width: 45px;
    height: 45px;
    background-repeat: no-repeat;
    background-size: 100%;
    position: absolute;
    right: 20px;
    top: 25px;
  }
  .kinds-of-job-item-list {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 40px;
  }
  .kinds-of-job-item {
    height: 350px;
  }
}
</style>
<template>
  <div class="development-block-content" id="development">
    <div class="development-block-content-container">
      <div class="development-block-content-header">ООО «КРУГ»</div>
      <div class="development-block-content-description">
        Мы ведем полный цикл разработки, развития, внедрения, сопровождении и эксплуатации ИТ – решений (программного обеспечения, программно-аппаратных комплексов) и в обеспечении функционирования информационной инфраструктуры реализации проектов, которые могут представлять государственную важность
        в рамках импортозамещения иностранных сервисов.
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DevelopmentBlock"
}
</script>

<style scoped>
.development-block-content-container {
  width: 903px;
  margin-top: 130px;
  height: 100%;
  background-image: url(~@/assets/images/monitor.png);
  background-repeat: no-repeat;
  background-position: bottom 170px right 100px;
}
.development-block-content {
  width: 100%;
  height: 679px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  background-image: url(~@/assets/images/separator.svg);
  background-repeat: no-repeat;
  background-position: bottom;
}
.development-block-content-header {
  height: 72px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 58px;
  line-height: 72px;
  display: flex;
  align-items: flex-end;
  color: #404040;
}
.development-block-content-description {
  height: 192px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  color: #000000;
  max-width: 638px;
  margin-top: 40px;
}
@media only screen and (max-width: 875px) {
  .development-block-content-container {
    width: 903px;
    margin-top: 60px;
    height: 100%;
    background-image: url(~@/assets/images/monitor.png);
    background-repeat: no-repeat;
    background-position: bottom 130px right 16px;
    margin-left: 20px;
  }
  .development-block-content-header {
    width: 335px;
    height: 30px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 30px;
    color: #242424;
  }
  .development-block-content-description {
    height: 220px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: #000000;
  }
}
</style>
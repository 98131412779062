<template>
  <div class="footerCmp">
    <div class="main-footer-info">
      <div>ООО «Круг»</div>
      <div class="main-footer-info-bordered"><a href="tel:+79636245858">+7 963 624 5858</a></div>
      <div class="main-footer-info-last"><a href="mailto:info@placetime.pro">info@placetime.pro</a></div>
    </div>
    <div class="second-footer-info">
      <div>ИНН: 1615016374</div>
      <div>ОГРН: 1211600026769</div>
      <div>КПП: 161501001</div>
    </div>
    <div class="description-footer-info">
      420500, респ. Татарстан, Верхнеуслонский р-н, г. Иннополис, Университетская ул., д. 7
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterCmp"
}
</script>

<style scoped>
.description-footer-info {
  margin-top: 20px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 32px;
  text-align: center;
  color: rgba(255, 255, 255, 0.5);
}
.second-footer-info {
  display: grid;
  grid-template-columns: 180px 180px 180px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 32px;
  text-align: center;
  color: rgba(255, 255, 255, 0.5);
  margin-top: 15px;

}
.main-footer-info-last {
  margin-left: 25px;
  cursor: pointer;
}
.main-footer-info-bordered {
  border-right: 2px solid #7134F3;
  border-left: 2px solid #7134F3;
  cursor: pointer;
}
.footerCmp {
  height: 235px;
  width: 100%;
  background-color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.main-footer-info {
  margin-top: 55px;
  display: grid;
  grid-template-columns: 180px 180px 180px;
  color: #FFFFFF;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 32px;
  text-align: center;
  letter-spacing: 0.06em;
  text-transform: uppercase;
}
@media only screen and (max-width: 750px) {
  .main-footer-info-last {
    margin: auto;
  }
  .main-footer-info-bordered {
    border: none;
  }
  .main-footer-info {
    display: flex;
    flex-direction: column;
  }
  .main-footer-info {
    margin-top: 45px;
  }
  .second-footer-info {
    display: flex;
    flex-direction: column;
  }
  .footerCmp {
    height: 400px;
  }
  .description-footer-info {
    width: 250px;
  }
}
</style>
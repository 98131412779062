import AppModal from '@/components/AppModal';

export const modalHandler = {
  components: { AppModal },

  props: {
    openModal: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isModalOpen: this.openModal,
    };
  },

  watch: {
    openModal() {
      this.isModalOpen = this.openModal;
    },
  },

  methods: {
    closeModal() {
      this.isModalOpen = false;

      this.$emit('closeModal');
    },
  },
};

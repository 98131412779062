<template>
  <div class="price-block">
    <div class="price-block-content">
      <h1 class="price-block-main-title">Баллы <span style="color: #7134F3">PlaceTime</span></h1>
      <div class="price-block-main-title-description">Баллы нужны для получения права использования расширенного
        функционала приложения
      </div>
      <div class="price-block-grid">
        <div class="price-block-grid-row">
          <div class="price-block-grid-th">Функции</div>
          <div class="price-block-grid-th">
            <div class="price-block-grid-th-price-block">
              <div class="price-block-grid-th-price-block-title">Основные</div>
              <div class="price-block-grid-th-price-block-description">
                <div class="price-block-grid-th-price-block-description-ico"></div>
                <div class="price-block-grid-th-price-block-description-text">0 баллов</div>
              </div>
            </div>
          </div>
          <div class="price-block-grid-th">
            <div class="price-block-grid-th-price-block">
              <div class="price-block-grid-th-price-block-title">Базовые</div>
              <div class="price-block-grid-th-price-block-description">
                <div class="price-block-grid-th-price-block-description-ico"></div>
                <div class="price-block-grid-th-price-block-description-text">500 баллов</div>
              </div>
            </div>
          </div>
          <div class="price-block-grid-th">
            <div class="price-block-grid-th-price-block">
              <div class="price-block-grid-th-price-block-title">Продвинутые</div>
              <div class="price-block-grid-th-price-block-description">
                <div class="price-block-grid-th-price-block-description-ico"></div>
                <div class="price-block-grid-th-price-block-description-text">1000 баллов</div>
              </div>
            </div>
          </div>
          <div class="price-block-grid-th">
            <div class="price-block-grid-th-price-block">
              <div class="price-block-grid-th-price-block-title">Продвинутые</div>
              <div class="price-block-grid-th-price-block-description">
                <div class="price-block-grid-th-price-block-description-ico"></div>
                <div class="price-block-grid-th-price-block-description-text">3000 баллов</div>
              </div>
            </div>
          </div>
        </div>
        <div class="price-block-grid-row">
          <div class="price-block-grid-td-text">Ведение личного профиля</div>
          <div class="price-block-grid-td-ico"></div>
          <div class="price-block-grid-td-ico"></div>
          <div class="price-block-grid-td-ico"></div>
          <div class="price-block-grid-td-ico"></div>
        </div>
        <div class="price-block-grid-row">
          <div class="price-block-grid-td-text">Ведение бесконечного кол-ва бизнес-профилей</div>
          <div class="price-block-grid-td-ico"></div>
          <div class="price-block-grid-td-ico"></div>
          <div class="price-block-grid-td-ico"></div>
          <div class="price-block-grid-td-ico"></div>
        </div>
        <div class="price-block-grid-row">
          <div class="price-block-grid-td-text">Социальная экономика и монетизация социальной активности</div>
          <div class="price-block-grid-td-ico"></div>
          <div class="price-block-grid-td-ico"></div>
          <div class="price-block-grid-td-ico"></div>
          <div class="price-block-grid-td-ico"></div>
        </div>
        <div class="price-block-grid-row">
          <div class="price-block-grid-td-text">Рекламный кабинет</div>
          <div class="price-block-grid-td-ico"></div>
          <div class="price-block-grid-td-ico"></div>
          <div class="price-block-grid-td-ico"></div>
          <div class="price-block-grid-td-ico"></div>
        </div>
        <div class="price-block-grid-row">
          <div class="price-block-grid-td-text">Визитка на карте</div>
          <div class="price-block-grid-td-ico"></div>
          <div class="price-block-grid-td-ico"></div>
          <div class="price-block-grid-td-ico"></div>
          <div class="price-block-grid-td-ico"></div>
        </div>
        <div class="price-block-grid-row">
          <div class="price-block-grid-td-text">Таргетированная реклама</div>
          <div></div>
          <div class="price-block-grid-td-ico"></div>
          <div class="price-block-grid-td-ico"></div>
          <div class="price-block-grid-td-ico"></div>
        </div>
        <div class="price-block-grid-row">
          <div class="price-block-grid-td-text">Товарная витрина</div>
          <div></div>
          <div class="price-block-grid-td-ico"></div>
          <div class="price-block-grid-td-ico"></div>
          <div class="price-block-grid-td-ico"></div>
        </div>
        <div class="price-block-grid-row">
          <div class="price-block-grid-td-text">Размещение рекламы в спец. местах</div>
          <div></div>
          <div></div>
          <div class="price-block-grid-td-ico"></div>
          <div class="price-block-grid-td-ico"></div>
        </div>
        <div class="price-block-grid-row">
          <div class="price-block-grid-td-text">Отключение рекламы преролл</div>
          <div></div>
          <div></div>
          <div class="price-block-grid-td-ico"></div>
          <div class="price-block-grid-td-ico"></div>
        </div>
        <div class="price-block-grid-row">
          <div class="price-block-grid-td-text">Индивидуальные условия по стримингу и конференциям</div>
          <div></div>
          <div></div>
          <div></div>
          <div class="price-block-grid-td-ico"></div>
        </div>
      </div>
      <div class="price-block-grid-mobile">
        <div class="tab">
          <input id="tab-one" type="checkbox" name="tabs" />
          <label for="tab-one" class="accordion-label">
            <div class="accordion-label-text">Основные</div>
            <div class="accordion-label-bonus-block">
              <div class="accordion-label-bonus-block-ico"></div>
              <div class="accordion-label-bonus-block-text">0 баллов</div>
            </div>
          </label>
          <div class="tab-content">
            <div class="bonus-grid">
              <div class="bonus-grid-ico"></div>
              <div class="bonus-grid-text">Ведение личного профиля</div>
              <div class="bonus-grid-ico"></div>
              <div class="bonus-grid-text">Ведение бесконечного кол-ва бизнес-профилей</div>
              <div class="bonus-grid-ico"></div>
              <div class="bonus-grid-text">Социальная экономика и монетизация социальной активности</div>
              <div class="bonus-grid-ico"></div>
              <div class="bonus-grid-text">Рекламный кабинет</div>
              <div class="bonus-grid-ico"></div>
              <div class="bonus-grid-text">Визитка на карте</div>
            </div>
          </div>
        </div>
        <hr class="devide-line">
        <div class="tab">
          <input id="tab-two" type="checkbox" name="tabs" />
          <label for="tab-two" class="accordion-label">
            <div class="accordion-label-text">Базовые</div>
            <div class="accordion-label-bonus-block">
              <div class="accordion-label-bonus-block-ico"></div>
              <div class="accordion-label-bonus-block-text">500 баллов</div>
            </div>
          </label>
          <div class="tab-content">
            <div class="bonus-grid">
              <div class="bonus-grid-ico-plus"></div>
              <div class="bonus-grid-text">Таргетированная реклама</div>
              <div class="bonus-grid-ico-plus"></div>
              <div class="bonus-grid-text">Товарная витрина</div>
            </div>
          </div>
        </div>
        <hr class="devide-line">
        <div class="tab">
          <input id="tab-three" type="checkbox" name="tabs" />
          <label for="tab-three" class="accordion-label">
            <div class="accordion-label-text">Продвинутые</div>
            <div class="accordion-label-bonus-block">
              <div class="accordion-label-bonus-block-ico"></div>
              <div class="accordion-label-bonus-block-text">1000 баллов</div>
            </div>
          </label>
          <div class="tab-content">
            <div class="bonus-grid">
              <div class="bonus-grid-ico-plus"></div>
              <div class="bonus-grid-text-bold">Базовые</div>
              <div class="bonus-grid-ico-plus"></div>
              <div class="bonus-grid-text">Размещение рекламы в спец. местах</div>
              <div class="bonus-grid-ico-plus"></div>
              <div class="bonus-grid-text">Отключение рекламы преролл</div>
            </div>
          </div>
        </div>
        <hr class="devide-line">
        <div class="tab">
          <input id="tab-four" type="checkbox" name="tabs" />
          <label for="tab-four" class="accordion-label">
            <div class="accordion-label-text">Профессиональные</div>
            <div class="accordion-label-bonus-block">
              <div class="accordion-label-bonus-block-ico"></div>
              <div class="accordion-label-bonus-block-text">3000 баллов</div>
            </div>
          </label>
          <div class="tab-content">
            <div class="bonus-grid">
              <div class="bonus-grid-ico-plus"></div>
              <div class="bonus-grid-text-bold">Базовые</div>
              <div class="bonus-grid-ico-plus"></div>
              <div class="bonus-grid-text-bold">Продвинутые</div>
              <div class="bonus-grid-ico-plus"></div>
              <div class="bonus-grid-text">Индивидуальные условия по стримингу и конференциям</div>
            </div>
          </div>
        </div>
        <hr class="devide-line">
      </div>
      <div class="price-block-notate">
        Все купленные баллы действительны в течении 1 года
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "price-block"
}
</script>

<style scoped>
.price-block-grid-mobile {
  display: none;
}

.price-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.price-block-content {
  background-color: #F9F5FF;
  min-width: 1920px;
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;

}

.price-block-main-title {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 44px;
  line-height: 72px;
  margin-top: 190px;
  margin-bottom: 0;
}

.price-block-main-title-description {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  text-align: center;
  width: 639px;
  /*margin-top: 1px;*/
}

.price-block-grid {
  margin-top: 60px;
}

.price-block-grid-row {
  display: grid;
  grid-template-columns: 300px 141px 141px 141px 141px;
  border-bottom: 1px solid #D9D9D9;
}

.price-block-grid-th {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
}

.price-block-grid-th-price-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.price-block-grid-th-price-block-description {
  display: flex;
  margin-top: 12px;
  margin-bottom: 23px;
}

.price-block-grid-th-price-block-description-ico {
  background-image: url(~@/assets/icons/money.svg);
  background-repeat: no-repeat;
  width: 20px;
  height: 17px;
}

.price-block-grid-th-price-block-description-text {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
}

.price-block-grid-td-text {
  font-family: 'SF Pro Text';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  height: 70px;
}

.price-block-grid-td-ico {
  background-image: url(~@/assets/icons/purple-accept.svg);
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  background-position: center;
  display: flex;
  align-items: center;
}

.price-block-notate {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 135%;
  text-align: center;
  color: #99969B;
  margin-top: 30px;
  margin-bottom: 170px;
}

@media only screen and (max-width: 900px) {
  .bonus-grid {
    display: grid;
    grid-template-columns: 60px 280px;
  }
  .bonus-grid-ico {
    background-image: url(~@/assets/icons/purple-accept.svg);
    background-size: 25%;
    background-repeat: no-repeat;
    /*width: 100%;*/
    height: 40px;
    background-position: center;
    display: flex;
    align-items: center;
    margin-left: -10px;
  }
  .bonus-grid-ico-plus {
    background-image: url(~@/assets/icons/purple-plus.svg);
    background-repeat: no-repeat;
    height: 44px;
    background-position: center;
    display: flex;
    align-items: center;
    margin-left: -10px;
  }
  .bonus-grid-text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    margin-top: 12px;
  }
  .bonus-grid-text-bold {
    margin-top: 12px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 150%;
  }
  .price-block-grid-mobile {
    display: flex;
    flex-direction: column;
    width: 98%;
    margin-top: 60px;
  }

  .price-block-main-title {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 30px;
    align-self: flex-start;
    margin-left: 20px;
    margin-top: 30px;
  }

  .price-block-main-title-description {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    width: 100%;
    align-self: flex-start;
    text-align: left;
    margin-left: 20px;
  }

  .price-block-content {
    width: 100%;
    min-width: auto;
  }

  .price-block-grid {
    display: none;
  }

  .price-block-notate {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 135%;
    text-align: center;
    margin-left: 20px;
    width: 80%;
    margin-top: 30px;
    margin-bottom: 90px;
  }

  .accordion-label-bonus-block {
    display: flex;
    margin-top: 5px;
  }

  .accordion-label-bonus-block-ico {
    background-image: url(~@/assets/icons/money.svg);
    background-repeat: no-repeat;
    width: 20px;
    height: 17px;
    margin-right: 5px;
  }

  .devide-line {
    width: 95%;
    border: 1px solid #D9D9D9;
    /*height: 1px;*/
  }

  /* Acordeon styles */
  .tab {
    position: relative;
    margin-bottom: 1px;
    width: 100%;
    overflow: hidden;
  }
  .tab input {
    position: absolute;
    opacity: 0;
    z-index: -1;
  }
  .accordion-label {
    position: relative;
    display: block;
    padding: 0 0 0 1em;
    /*line-height: 3;*/
    cursor: pointer;
    font-family: 'SF Pro Text';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
  }
  .blue label {
    background: #2980b9;
  }
  .tab-content {
    max-height: 0;
    overflow: hidden;
    -webkit-transition: max-height .35s;
    -o-transition: max-height .35s;
    transition: max-height .35s;
  }
  .blue .tab-content {
    background: #3498db;
  }
  /* :checked */
  .tab input:checked ~ .tab-content {
    max-height: 100vh;
  }
  /* Icon */
  .tab label::after {
    position: absolute;
    right: 0;
    top: 0;
    display: block;
    width: 3em;
    height: 3em;
    line-height: 19px;
    text-align: center;
    -webkit-transition: all .35s;
    -o-transition: all .35s;
    transition: all .35s;
  }
  .tab input[type=checkbox] + label::after {
    content: url(~@/assets/icons/arrow-down.svg);
  }
  .tab input[type=radio] + label::after {
    content: "\25BC";
  }
  .tab input[type=checkbox]:checked + label::after {
    transform: rotate(180deg);
    top: -25px;
  }
  .tab input[type=radio]:checked + label::after {
    transform: rotateX(180deg)
  }
  /* Acordeon styles  end*/
}
</style>